import React, { useEffect } from "react";
import themeStyle from "../../styles/theme.style";
import AboutUsImg from "../../assets/images/about-us.webp";
import { companyName, registeredCompanyName } from "../../Config/config";
import { updateMetaDescription } from "../../Config/functions";

function AboutUs() {
  useEffect(() => {
    document.title = "About US | " + companyName;
    updateMetaDescription("About US | " + companyName);
  }, []);
  const CheckMark = () => <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
  </svg>
  return (
    <div className="px-6 md:px-32 py-16 w-full">
      <h1 className="text-black text-4xl font-bold text-center">{companyName} - About Us</h1>
      <div className="lg:flex items-center flex-col md:flex-row justify-center">
        <div className="lg:w-[30%] mt-5 flex justify-center">
          <img
            className="rounded-3xl"
            height={400}
            width={400}
            src={AboutUsImg}
            alt="about-us"
          />
        </div>
        <div className="lg:w-[70%] mb-6 md:mb-0 md:mx-10 mt-10">
          <p className="text-xl/8 tracking-wid text-zinc-500">
            Welcome to {companyName} is a part of {registeredCompanyName}, your trusted partner in navigating the complexities of legal compliance and business growth. With a steadfast commitment to excellence, we empower entrepreneurs, startups, and established businesses to focus on what they do best—innovating, scaling, and thriving—while we handle the legal groundwork.
          </p>
          <br />
        </div>
      </div>
      {/* Who We Are */}
      <h2 className="text-black text-2xl font-bold text-center underline underline-offset-4 my-5">Who We Are</h2>
      <p className="text-xl/8 tracking-wide text-zinc-500">
        At {companyName}, we specialize in providing comprehensive legal and regulatory services tailored to the dynamic needs of modern businesses. Founded by a team of seasoned legal professionals and business experts, our mission is to simplify legal processes, reduce administrative burdens, and ensure compliance with India’s ever-evolving regulatory landscape. Whether you’re launching a startup, protecting your brand, or scaling operations, we’re here to deliver seamless solutions with precision and care.
      </p>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      {/* Why Choose Us? */}
      <h2 className="text-black text-2xl font-bold text-center underline underline-offset-4 my-5">Why Choose Us?</h2>
      <ul className="text-xl/8 tracking-wide text-zinc-500">
        <li class="flex items-center">
          <CheckMark />
          Expertise You Can Trust: Our team combines years of legal experience with deep industry knowledge, offering end-to-end guidance for trademarks, GST registration, company incorporation, and more.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Client-Centric Approach: We prioritize your unique needs, providing personalized solutions that align with your business goals.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Transparent & Affordable: No hidden fees, no jargon—just clear pricing and honest communication.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Speed & Efficiency: Leverage our tech-driven processes to fast-track registrations, filings, and compliance deadlines.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Proven Track Record: Thousands of satisfied clients, from solo entrepreneurs to mid-sized enterprises, trust us as their legal partner.
        </li>
      </ul>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      {/* Our Services */}
      <h2 className="text-black text-2xl font-bold text-center underline underline-offset-4 my-5">Our Services</h2>
      <p className="text-xl/8 tracking-wide text-zinc-500">We offer a full suite of legal and business compliance services, including:</p>
      <ul className="text-xl/8 tracking-wide text-zinc-500">
        <li class="flex items-center">
          <CheckMark />
          Trademark Registration: Protect your brand identity with end-to-end trademark search, filing, and monitoring.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Company Registration: Incorporate your business seamlessly (Private Limited, LLP, OPC, or Startup India) with expert guidance.
        </li>
        <li class="flex items-center">
          <CheckMark />
          GST Registration & Compliance: Simplify GST enrollment, returns filing, and advisory services.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Taxation & Licensing: Manage ITR filing, TDS compliance, and industry-specific licenses.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Intellectual Property Services: Safeguard patents, copyrights, and designs.
        </li>
        <li class="flex items-center">
          <CheckMark />
          Annual Compliance: Stay audit-ready with ROC filings, MCA compliance, and more.
        </li>
      </ul>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      {/* Our Commitment */}
      <h2 className="text-black text-2xl font-bold text-center underline underline-offset-4 my-5">Our Commitment</h2>
      <p className="text-xl/8 tracking-wide text-zinc-500">Legal and regulatory hurdles shouldn’t slow your progress. At Go Legal India, we blend professionalism with empathy, ensuring you’re always informed, compliant, and confident. We stay ahead of regulatory changes so you don’t have to, offering proactive advice to future-proof your business.</p>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className="lg:flex items-start flex-col md:flex-row lg:gap-x-20">
        {/* Join Our Growing Community */}
        <div className="lg:w-[50%] mt-5 justify-center">
          <h2 className="text-black text-2xl font-bold text-center underline underline-offset-4 my-5">Join Our Growing Community</h2>
          <p className="text-xl/8 tracking-wide text-zinc-500">From passionate solopreneurs to ambitious enterprises, we’ve helped businesses across industries lay a strong legal foundation. Let us handle the paperwork, deadlines, and complexities—you focus on turning your vision into reality.</p>
        </div>
        <div className="lg:w-[50%] mt-5 justify-center">
          {/* Ready to Begin? */}
          <h2 className="text-black text-2xl font-bold text-center underline underline-offset-4 my-5">Ready to Begin?</h2>
          <p className="text-xl/8 tracking-wide text-zinc-500">Reach out today to discuss how we can support your journey. Together, we’ll build a compliant, secure, and thriving business.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
