export default [
    {
        "title": "Business Registration",
        "description": "Starting a new venture? We provide end-to-end assistance in registering your business, ensuring compliance with all legal requirements."
    },
    {
        "title": "Brand Registration",
        "description": "Protect your brand identity with our trademark and brand registration services, safeguarding your intellectual property rights."
    },
    {
        "title": "Legal Document Drafting",
        "description": "Our experienced legal team offers precise and customized drafting of legal documents tailored to your specific needs."
    },
    {
        "title": "GST Services",
        "description": "Navigate the complexities of Goods and Services Tax (GST) with our expert guidance, from registration to filing returns."
    },
    {
        "title": "Income Tax Filing",
        "description": "Ensure timely and accurate filing of your income tax returns with our professional assistance, minimizing liabilities and maximizing compliance."
    },
    {
        "title": "ITR Filing",
        "description": "Exercise your right to information effectively by utilizing our services to file Right to Information (RTI) applications seamlessly."
    },
    {
        "title": "Legal Notices",
        "description": "We assist in drafting and sending legal notices, a crucial step in initiating legal action and asserting your rights."
    },
    {
        "title": "Consultation with Lawyers",
        "description": "Connect with our network of experienced lawyers for personalized legal advice and consultations on various matters."
    },
]