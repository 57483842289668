import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import themeStyle from "../styles/theme.style";
import { LiaStarSolid } from "react-icons/lia";
import rightArrowIcon from "../assets/images/right-arrow.webp";
import leftArrowIcon from "../assets/images/left-arrow.webp";
import ASSOCIATED_1 from "../assets/images/Associated/ASSOCIATED_1.webp";
import ASSOCIATED_2 from "../assets/images/Associated/ASSOCIATED_2.webp";
import ASSOCIATED_3 from "../assets/images/Associated/ASSOCIATED_3.webp";
import ASSOCIATED_4 from "../assets/images/Associated/ASSOCIATED_4.webp";
import ASSOCIATED_5 from "../assets/images/Associated/ASSOCIATED_5.webp";
import ASSOCIATED_6 from "../assets/images/Associated/ASSOCIATED_6.webp";
import ASSOCIATED_7 from "../assets/images/Associated/ASSOCIATED_7.webp";
const associatedArray = [
  { image: ASSOCIATED_1, alt: "Go Daddy" },
  { image: ASSOCIATED_2, alt: "PayTm" },
  { image: ASSOCIATED_3, alt: "HDFC Bank" },
  { image: ASSOCIATED_4, alt: "ICICI Bank" },
  { image: ASSOCIATED_5, alt: "Razor Pay" },
  { image: ASSOCIATED_6, alt: "Google Partner" },
  { image: ASSOCIATED_7, alt: "Trust Pilot" },
];

const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-[40%] cursor-pointer z-10 right-[-9%]  lg:right-[-5%]"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={32}
        height={32}
        fill="red"
        stroke="red"
        viewBox="0 0 330 330"
      >
        <path d="m250.606 154.389-150-149.996c-5.857-5.858-15.355-5.858-21.213.001-5.857 5.858-5.857 15.355.001 21.213l139.393 139.39L79.393 304.394c-5.857 5.858-5.857 15.355.001 21.213C82.322 328.536 86.161 330 90 330s7.678-1.464 10.607-4.394l149.999-150.004a14.996 14.996 0 0 0 0-21.213z" />
      </svg>
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-[40%] cursor-pointer z-10 left-[-9%]  lg:left-[-5%]"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={32}
        height={32}
        viewBox="0 0 34.075 34.075"
      >
        <path
          d="M24.57 34.075a1.964 1.964 0 0 1-1.396-.577L8.11 18.432a1.972 1.972 0 0 1 0-2.79L23.174.578a1.973 1.973 0 1 1 2.791 2.79l-13.67 13.669 13.67 13.669a1.974 1.974 0 0 1-1.395 3.369z"
          style={{
            fill: "red",
          }}
        />
      </svg>
    </div>
  );
};

const AssociatedCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="px-12 sm:px-16 py-16  w-full"
      style={{ backgroundColor: themeStyle.COLOR_LIGHT_GREY }}
    >
      <div style={{ margin: "" }}>
        <h2 className="text-black text-4xl font-bold mb-3 text-center">
          We Are Associated with
        </h2>
        <div className="mb-16 md:mx-16">
          <Slider {...settings}>
            {associatedArray.map((associated, index) => (
              <div key={index}>
                <div className="p-2 m-3 rounded-xl overflow-hidden">
                  <div className="flex justify-center">
                    <img
                      src={associated.image}
                      alt={associated.alt}
                      className="w-full items-center"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AssociatedCarousel;
