import React from 'react'
import lisencingLogo from '../../assets/images/licensing.webp'

function TypesOfFssaiLicense() {

    const licenses = [
        {
          title: 'FSSAI Basic registration',
          description: 'This is required for small business or startups which have annual turnover below Rs.12 lakhs. Basic registration can be upgraded to state license as your sales graph increases.',
        },
        {
          title: 'FSSAI State license',
          description: 'This license is applicable to mid-sized companies which have annual turnover between Rs.12-20 crores.',
        },
        {
          title: 'FSSAI Central license',
          description: 'This license is applicable typically on large business with annual turnover above Rs.20 crores. It is also required in cases where you need to supply at the government offices or import/export food products.',
        },
      ];

    const LicenseCard = ({ title, description }) => {
        return (
            <div className="bg-white rounded-lg border p-6 text-center">
                <div className="text-4xl mb-4 flex justify-center">
                    <img height={100} width={100} src={lisencingLogo} />
                </div>
                <h2 className="text-xl font-bold mb-3">{title}</h2>
                <p className="text-gray-700">{description}</p>
            </div>
        );
    };

    return (
        <div className="px-6 md:px-10 py-8 w-full">
            <div className='my-5'>
                <h2 className='text-black text-4xl font-bold text-center mb-3'>Types of FSSAI License</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 md:gap-y-10 gap-y-10 py-16 lg:mx-20 md:mx-16 mx-2'>
                    {licenses.map((license, index) => (
                        <LicenseCard key={index} title={license.title} description={license.description} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TypesOfFssaiLicense