import React, { useEffect, useState } from 'react'
import UpArrowIcon from '../assets/svg/UpArrowIcon'

const BackToTopButton = () => {
  const [backToTop, setBackToTop] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) setBackToTop(true)
      else setBackToTop(false)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const ScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <div className='flex justify-center'>
      <button
        onClick={ScrollUp}
        className={`bg-white text-white text-sm font-semibold flex justify-center items-center p-2 rounded-full shadow-lg hover:bg-red-600 transition duration-300 transform border border-red-500 ${backToTop ? "opacity-100 translate-y-0 mt-5" : "opacity-0 translate-y-10"
          }`}
      >
        <UpArrowIcon width={25} height={25} />
      </button>
    </div>
  )
}

export default BackToTopButton
