import React, { useEffect } from 'react'
import HomeIntro from './HomeIntro'
import RegistrationProcedure from './RegistrationProcedure'
import Carousel from '../../components/Carousel'
import AssociatedCarousel from '../../components/AssociatedCarousel'
import { companyName } from '../../Config/config'
import MetaManager from '../../components/MetaManager'
import OurServices from '../../components/OurServices/OurServices'

function Home() {
  useEffect(() => {
    document.title = companyName
  }, [])
  return (
    <div>
      <MetaManager
        title='Go Legal India - Online Legal Services'
        description='Online legal service provider offering FSSAI License, Trademark & GST registration, and more, with 1.5L+ happy customers and 4.7-star Google reviews.'
      />
      <HomeIntro />
      <OurServices />
      <RegistrationProcedure />
      <Carousel />
      <AssociatedCarousel />
      {/* <FoodLicensed/> */}
    </div>
  )
}
// [grw id=18770]
export default Home