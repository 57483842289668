import { ReactGoogleReviews } from "react-google-reviews";
import "react-google-reviews/dist/index.css";
import { companyName } from "../Config/config";
import { useEffect } from "react";

export default function FeaturableReviews() {
    // Create a free Featurable account at https://featurable.com
    // Then create a new Featurable widget and copy the widget ID
    const featurableWidgetId = "0ef6f7b3-6c07-4cae-b3b2-5a9b47250d85";
    useEffect(() => {
    }, [])
    return (
        <ReactGoogleReviews
            layout="carousel"
            featurableId={featurableWidgetId}
        />
    );
}
