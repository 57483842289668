import React from 'react'
import OurServicesData from './OurServicesData'

const OurServices = () => {

    return (
        <section className="px-6 md:px-10 pt-4  w-full" id='Our-Services'>
            <div className='my-5 lg:mx-20 md:mx-16 mx-2'>
                <h2 className='text-black text-4xl font-bold mb-3 underline decoration-red-500  underline-offset-8'>Our Services</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-10 py-10'>
                    {OurServicesData.map((item, index) => <div key={index.toString()}>
                        <h3 className='text-black/80 text-xl font-semibold' >{item.title}</h3>
                        <p className='text-gray-700'>{item.description}</p>
                    </div>)}
                </div>
            </div>
        </section>
    )
}

export default OurServices