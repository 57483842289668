import React from "react";
import { Helmet } from "react-helmet";

const MetaManager = ({
  title = "",
  description = "",
  keywords = "",
  author = "",
  canonical = "",
  seo = false,
}) => {
  return (
    <>
      <Helmet>
        {title ? <title>{title}</title> : null}
        {description ? <meta name="description" content={description} /> : null}
        {keywords ? <meta name="keywords" content={keywords} /> : null}
        {author ? <meta name="author" content={author} /> : null}
        <link rel="canonical" href={canonical || window.location.href} />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          name: "Go Legal India",
          image:
            "https://golegalindia.com/static/media/Logo.dfc6efe558ec56996a4f.png",
          description:
            "Go Legal India is an online legal service provider. We cover all your business registration needs in one place with over 10,000 satisfied customers.",
          address: {
            "@type": "PostalAddress",
            streetAddress: "33H/3 Mominpore Road",
            addressLocality: "Kolkata",
            addressRegion: "West Bengal",
            postalCode: "700023",
            addressCountry: "IN",
          },
          telephone: "+91 74397 75535",
          url: "https://golegalindia.com/",
          hasService: [
            {
              "@type": "Service",
              name: "FSSAI Registration",
              description:
                "Obtain the necessary license for your food business.",
            },
            {
              "@type": "Service",
              name: "Company Registration",
              description:
                "Register your private limited company, LLP, or one person company.",
            },
            {
              "@type": "Service",
              name: "Trademark Registration",
              description: "Protect your brand name and logo.",
            },
            {
              "@type": "Service",
              name: "Tax & Compliance",
              description: "File your GST returns and income tax returns.",
            },
            {
              "@type": "Service",
              name: "Drafting & Filing",
              description:
                "Get legal documents drafted and filed by experienced professionals.",
            },
          ],
        })}
      </script>
    </>
  );
};

export default MetaManager;
